import React from 'react';

import { Helmet } from 'react-helmet';

export default function FaqSeo(props) {
	let { questions } = props;

	let entities = [];
	for (let question of questions) {
		entities.push({
			'@type': 'Question',
			'name': question.question,
			'acceptedAnswer': { '@type': 'Answer', 'text': question.answer },
		});
	}

	let json = { '@context': 'https://schema.org', '@type': 'FAQPage', 'mainEntity': entities };

	return (
		<Helmet>
			<script type="application/ld+json">{JSON.stringify(json)}</script>
		</Helmet>
	);
}
