import React, { useEffect, useState } from 'react';

import classNames from 'classnames';
import { graphql, Link, navigate } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';

import Button from '../components/button.jsx';
import Layout from '../components/layout.jsx';
import FaqSeo from '../components/faq-seo.jsx';
import Section from '../components/section.jsx';
import TitleBlock from '../components/title-block.jsx';
import Testimonials from '../components/testimonials.jsx';

import useTranslate from '../hooks/use-translate.js';
import Hero from '../components/hero.jsx';
import { ChevronRightIcon } from '@heroicons/react/outline';
import Title from '../components/title.jsx';
import RichContent from '../components/rich-content.jsx';

export default function Faq(props) {
	let { data } = props;
	let t = useTranslate('faq');

	let { sanityFaqPage } = data;
	let { title, categories } = sanityFaqPage;
	let [selectedCategory, setSelectedCategory] = useState();
	let [selectedQuestionKey, setSelectedQuestionKey] = useState();

	useEffect(() => {
		let searchParams = new URLSearchParams(document.location.search);
		let type = searchParams.get('category');
		let category = categories.find((c) => c.name.replace(/\s/g, '-').toLowerCase() === type?.toLowerCase());
		if (category) {
			setSelectedCategory(category);
		} else {
			setSelectedCategory(categories[0]);
			let name = categories[0].name.replace(/\s/g, '-').toLowerCase();
			navigate(`?type=${name}`, { replace: true });
		}
	}, [categories]);

	function handleCategoryClick(event, category) {
		event.preventDefault();
		setSelectedCategory(category);
		let name = category.name.replace(/\s/g, '-').toLowerCase();
		navigate(`?category=${name}`, { replace: true });
	}

	function handleQuestionClick(key) {
		if (selectedQuestionKey === key) {
			setSelectedQuestionKey();
		} else {
			setSelectedQuestionKey(key);
		}
	}

	let renderFaqCategories = [];
	for (let category of categories) {
		let className = classNames(
			'px-2 py-1 block mx-2 my-2 border rounded-full border-primary text-primary focus:outline-none focus-visible:bg-primary focus-visible:text-secondary',
			{
				'text-secondary bg-primary': category.name === selectedCategory?.name,
				'text-primary': category.name !== selectedCategory?.name,
			},
		);

		let name = category.name.replace(/\s/g, '-').toLowerCase();
		renderFaqCategories.push(
			<li key={category._key}>
				<Link to={`?type=${name}`} onClick={(event) => handleCategoryClick(event, category)} className={className}>
					{category.name}
				</Link>
			</li>,
		);
	}

	let renderQuestions = [];
	for (let question of selectedCategory?.questions || []) {
		let renderAnswer;
		if (question._key === selectedQuestionKey) {
			renderAnswer = (
				<div className="max-w-5xl mt-6 whitespace-pre-line">
					<RichContent className="prose lg:prose-lg" blocks={question._rawAnswer} />
				</div>
			);
		}

		let arrowClassName = classNames('w-7 h-7 transition-all transform', {
			'rotate-90': question._key === selectedQuestionKey,
		});

		let buttonClassName = classNames(
			'flex items-center justify-between w-full text-xl text-left md:text-2xl focus:outline-none group',
			{ 'text-white font-semibold': question._key === selectedQuestionKey },
			{ 'text-tertiary': question._key !== selectedQuestionKey },
		);

		renderQuestions.push(
			<div key={question._key}>
				<button className={buttonClassName} onClick={() => handleQuestionClick(question._key)}>
					{question.question}
					<ChevronRightIcon className={arrowClassName} />
				</button>
				{renderAnswer}
			</div>,
		);
	}

	let allQuestions = [];
	for (let category of categories) {
		allQuestions.push(...category.questions);
	}

	return (
		<Layout>
			<FaqSeo questions={allQuestions} />

			<Hero>
				<div className="grid gap-12 lg:grid-cols-2">
					<h1 className="text-4xl md:text-5xl 2xl:text-6xl self-y-center">
						<TitleBlock title={title} />
					</h1>

					<div className="relative w-full rounded-full lg:h-full lg:w-auto lg:max-w-none">
						<StaticImage src="../images/hero-faq.svg" alt="Building" />
					</div>
				</div>
			</Hero>

			<Section>
				<ul className="flex flex-wrap items-center justify-center">{renderFaqCategories}</ul>
			</Section>

			<Section>
				<Title>{selectedCategory?.name}</Title>
				<div className="grid gap-12 pt-16">{renderQuestions}</div>
				<div className="mt-24 text-center">
					<p className="text-2xl">{t('answer-not-found')}</p>
					<span className="inline-block mt-4">
						<Button as="link" to={'../contact'}>
							{t('answer-not-found-cta-text')}
						</Button>
					</span>
				</div>
			</Section>
		</Layout>
	);
}

export const query = graphql`
	query FaqPage($language: String!) {
		sanityFaqPage(lang: { eq: $language }) {
			title {
				children {
					_key
					text
					marks
				}
			}
			categories {
				_key
				name
				questions {
					_key
					question
					_rawAnswer(resolveReferences: { maxDepth: 5 })
				}
			}
		}
	}
`;
